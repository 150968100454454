import * as signalR from '@microsoft/signalr';

let config = {
  apiSpmHost: "https://slidespielspeakersapi.azurewebsites.net",
  apiPortalHost: "https://api.slidespiel.com",
  apiExportHost: "https://sspexportwa.azurewebsites.net",
  apiSignalRHost: "https://ssprealtimeapi.azurewebsites.net",
  chatRealm: "https://chat.slidespiel.com",
  imageQuality: 3,
  // signallingServer: "http://127.0.0.1:3001",
  signallingServer: "https://rtc.slidespiel.com",
  // amplitudeKey: "bc984d3a3a4d5e3ae76ea8463804ddb0",
  sendChunks: true,
  debug: "mediasoup-client:none",
  version: "1.24.10",
  showMs: true,
  uniqueStreamerNames: false,
  feedQueryCount: 9999999,
  disablePreroll: true,
  // spmDomains: [{ domain: "localhost", spm: "test-spm" }],
  spmDomains: [],
  defaultSyncDate: "2025-03-09",
  // defaultSyncDate: "2024-09-23",
  // spellChecker: disable-next-line,
  captchaSiteKey: "6Lfm-tMmAAAAAB5wrrJQOTTVO0wJ_DysZ-mucuoI",
  showFilterCollapseButton: true,
  signalRLogLevel: signalR.LogLevel.Information,
};
// eslint-disable-next-line
if (process.env.NODE_ENV === "production") {
  config = {
    ...config, ...{
      imageQuality: 2,
      signallingServer: "https://rtc.slidespiel.com",
      // amplitudeKey: "5d638d7af30ced527d1fa80d2c5bd116",
      sentryDsn: "https://36847a217c354163b2f67ff6e413075f@o361175.ingest.sentry.io/5195220",
      sendChunks: true,
      debug: "mediasoup-client:*",
      showMs: false,
      uniqueStreamerNames: true,
      feedQueryCount: 30,
      disablePreroll: false,
      spmDomains: [],
      defaultSyncDate: null,
      showFilterCollapseButton: false,
      signalRLogLevel: signalR.LogLevel.None,
    }
  };
}

export default config;
