//spell-checker:disable
import axios from "axios";
import config from 'config';

const { apiSpmHost: apiHost } = config;

const getApi = (spmName) => ({
  tokenName: `${spmName}-access_token`,
  restoreUser() {
    return new Promise((resolve, reject) => {
      let token = "";
      try {
        token = localStorage.getItem(this.tokenName);
      }
      catch (ex) {
        console.warn("[SPM API]", "error reading local storage", ex);
      }
      if (!token) {
        reject();
      } else {
        this.getUser().then(resolve, () => {
          localStorage.removeItem(this.tokenName);
          reject();
        });
      }
    });
  },

  getUser(id = "me") {
    return this.req(`user/${id}`);
  },

  findUser(email) {
    return this.req(`user/?email=${email}`);
  },

  logIn(associationId, username, password, eventId) {
    console.log(associationId);
    let data = {
      grant_type: "password",
      username,
      password,
    };
    if (associationId)
      data = { ...data, associationId };
    if (eventId)
      data = { ...data, eventId };
    return new Promise((resolve, reject) => {
      axios({
        url: `${apiHost}/token`,
        method: 'post',
        data: new URLSearchParams(data),
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      }).then(({ data }) => {
        //console.log(data);
        localStorage.setItem(this.tokenName, data.access_token);
        this.getUser().then(resolve, reject);
      }).catch(error => {
        const { response } = error;
        if (!response) {
          console.error('LOGIN ERROR', error.toJSON());
          reject(error.message ? { error_description: error.message } : "Unknown error");
          return;
        }
        reject(response.data);
      });
    });
  },
  demoRequest(registrationData) {
    return this.req("demo", "post", registrationData);
  },
  updateViewCount(recordingId) {
    return this.req(`recording/${recordingId}/view?x=${Date.now()}`, "get");
  },
  postToLog(data) {
    return this.req(`events/log`, "post", data).catch(e => console.error("[LOG]", "error writing to log", e));
  },
  getPresentation(contentId) {
    return this.req('presentation/' + contentId);
  },
  getFeed(eventId, from = 0, to = 50) {
    return this.req(`events/${eventId}/log/${from}/${to}`);
  },
  getRoomPresentations(roomId) {
    return this.req(`room/${roomId}/presentations`);
  },
  getRoomSessions(roomId) {
    return this.req(`room/${roomId}/sessions`);
  },
  getRecordingSyncTable(recordingId) {
    return this.req(`recording/${recordingId}/sync-table`);
  },
  restoreRecordingSyncTable(recordingId) {
    return this.req(`recording/${recordingId}/sync-table/restore`, "post");
  },
  createRecordingSyncTableRecord(recordingId, data) {
    return this.req(`recording/${recordingId}/sync-table`, "post", data);
  },
  updateRecordingSyncTableRecord(recordingId, id, data) {
    return this.req(`recording/${recordingId}/sync-table/${id}`, "put", data);
  },
  deleteRecordingSyncTableRecord(recordingId, id) {
    return this.req(`recording/${recordingId}/sync-table/${id}`, "delete");
  },
  getEvent(alias) {
    return this.req('events?alias=' + alias);
  },
  getExhibitors(eventId) {
    return this.req(`events/${eventId}/exhibitors`);
  },
  getExhibitor(id) {
    return this.req(`exhibitor/${id}`);
  },
  getPortals() {
    return this.req(`portals`);
  },
  getPortal(id) {
    return this.req(`portals/${id}`);
  },
  deletePortal(id) {
    return this.req(`portals/${id}`, "delete");
  },
  createPortal(data) {
    return this.req(`portals`, "post", data);
  },
  updatePortal(id, data) {
    return this.req(`portals`, "put", { id, ...data });
  },
  addEventToPortal(portalId, eventId) {
    return this.req(`portals/${portalId}/events/${eventId}`, "put");
  },
  removeEventFromPortal(portalId, eventId) {
    return this.req(`portals/${portalId}/events/${eventId}`, "delete");
  },
  getAssociations(includeEvents = false) {
    return this.req(`associations?includeEvents=${includeEvents}&x=${Date.now()}`);
  },
  getAssociationUsers(id) {
    return this.req(`association/${id}/admins`);
  },
  addUserToAssociation(userId, associationId) {
    return this.req(`user/${userId}/associations/${associationId}`, "post");
  },
  removeUserFromAssociation(userId, associationId) {
    return this.req(`user/${userId}/associations/${associationId}`, "delete");
  },
  createUser(data) {
    return this.req(`user`, "post", data);
  },
  editUser(id, data) {
    return this.req('user/' + id, "put", data);
  },
  createAssociation(data) {
    return this.req(`associations`, "post", data);
  },
  updateAssociation(id, data) {
    return this.req(`associations/${id}`, "put", data);
  },
  createEvent(associationId, data) {
    return this.req(`associations/${associationId}/events`, "post", data);
  },
  getEventById(id) {
    return this.req('events/' + id);
  },
  editEvent(id, data) {
    return this.req('events/' + id, "put", data);
  },
  createExhibitor(eventId, data) {
    return this.req(`events/${eventId}/exhibitor`, "post", data);
  },
  updateExhibitor(id, data) {
    return this.req('exhibitor', "put", { id, ...data });
  },
  deleteExhibitor(id) {
    return this.req(`exhibitor/${id}`, "delete");
  },
  reorderExhibitors(data) {
    return this.req(`exhibitors/order`, "put", data);
  },
  createExhibitorRepresentative(exhibitorId, data) {
    return this.req(`exhibitor/${exhibitorId}/representatives`, "post", data);
  },
  deleteExhibitorRepresentative(exhibitorId, representativeId) {
    return this.req(`exhibitor/${exhibitorId}/representatives/${representativeId}`, "delete");
  },
  deleteExhibitorFile(exhibitorId, fileId) {
    return this.req(`exhibitor/${exhibitorId}/files/${fileId}`, "delete");
  },
  getExhibitorsReport(eventId) {
    return this.req(`events/${eventId}/exhibitReport`, 'get', null, { responseType: 'blob', });
  },
  getCmsPortal(portalId) {
    return this.req(`cms/portals/${portalId}`);
  },
  getCeTypes() {
    return this.req(`cms/ceTypes`);
  },
  updateCeTypes(data) {
    return this.req(`cms/ceTypes`, 'put', data);
  },
  getQuestions(id) {
    return this.req(`cms/recording/${id}/questions`);
  },
  updateQuestions(id, data) {
    return this.req(`cms/recording/${id}/questions`, 'put', data);
  },
  getTrack(id) {
    return this.req(`cms/tracks/${id}`);
  },
  createTrack(data) {
    return this.req(`cms/tracks`, 'post', data);
  },
  updateTrack(data) {
    return this.req(`cms/tracks/${data.id}`, 'put', data);
  },
  deleteTrack(id) {
    return this.req(`cms/tracks/${id}`, 'delete');
  },
  reorderTracks(data) {
    return this.req(`cms/tracks/order`, "put", data);
  },
  getSponsors(id, entity) {
    return this.req(`${entity}/${id}/sponsorship`);
  },
  updateSponsors(id, entity, data) {
    return this.req(`${entity}/${id}/sponsorship`, "post", data);
  },
  getCertificationPackages(portalId) {
    return this.req(`portals/${portalId}/certificationPackages`);
  },
  updateCertificationPackages(portalId, data) {
    return this.req(`portals/${portalId}/certificationPackages`, "post", data);
  },
  getPortalRecordings(portalId) {
    return this.req(`portals/${portalId}/recordings`);
  },
  getPortalTracks(portalId) {
    return this.req(`portals/${portalId}/tracks`);
  },
  deleteEvent(id) {
    return this.req('events/' + id, "delete");
  },
  getRooms(eventId) {
    return this.req(`event/${eventId}/rooms?x=${Date.now()}`);
  },
  getRtmpRooms() {
    return this.req(`rtmp/rooms`);
  },
  getConverterQueue() {
    return this.req("/presQueue");
  },
  createRoom(eventId, data) {
    return this.req(`event/${eventId}/room`, "post", data)
  },
  updateRoom(roomId, { name, chatGroup, rtmpEnabled, rtmpOnlyPlayer, rtmpUrl, rtmpEndpoint, order, rtmpState, hideFromShedule, oneWayChat, externalScreenSource }) {
    return this.req(`room?id=${roomId}&name=${encodeURIComponent(name)}&chatGroup=${chatGroup}&rtmpEnabled=${rtmpEnabled}&rtmpOnlyPlayer=${rtmpOnlyPlayer}&rtmpUrl=${rtmpUrl}&rtmpEndpoint=${rtmpEndpoint}&order=${order}&rtmpState=${rtmpState}&hideFromShedule=${hideFromShedule}&oneWayChat=${oneWayChat}&externalScreenSource=${externalScreenSource}`, "put");
  },
  deleteRoom(roomId) {
    return this.req(`room/${roomId}`, "delete")
  },
  reorderRooms(data) {
    return this.req(`rooms/order`, "put", data);
  },
  getNetworkingRooms(eventId) {
    return this.req(`events/${eventId}/netRooms`);
  },
  createNetworkingRoom(eventId, data) {
    return this.req(`events/${eventId}/netRoom`, "post", data);
  },
  updateNetworkingRoom(eventId, roomId, data) {
    return this.req(`events/${eventId}/netRoom`, "put", { id: roomId, ...data })
  },
  deleteNetworkingRoom(eventId, roomId) {
    return this.req(`events/${eventId}/netRoom/${roomId}`, "delete")
  },
  reorderNetworkingRooms(eventId, data) {
    return this.req(`events/${eventId}/netRooms/order`, "put", data);
  },
  getEventCustomPages(eventId) {
    return this.req(`events/${eventId}/pages`);
  },
  getEventPrerollVideos(eventId) {
    return this.req(`event/${eventId}/preroll-videos`);
  },
  createEventPrerollVideo(eventId, name, isDefault, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("isDefault", isDefault);
    return this.req(`event/${eventId}/preroll-videos`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },
  updateEventPrerollVideo(eventId, videoId, data) {
    return this.req(`event/${eventId}/preroll-videos/${videoId}`, "put", data);
  },
  deleteEventPrerollVideo(eventId, videoId) {
    return this.req(`event/${eventId}/preroll-videos/${videoId}`, "delete");
  },
  updateRecordingPrerollVideo(recordingId, videoId) {
    return this.req(`recording/${recordingId}/preroll-video`, "put", { prerollVideoId: videoId });
  },
  createEventCustomPage(eventId, { name, alias, content, isPublic }) {
    return this.req(`events/${eventId}/page`, "post", { name, alias, text: content, isPublic });
  },
  updateEventCustomPage(eventId, pageId, { name, alias, content, isPublic }) {
    return this.req(`events/${eventId}/page`, "put", { id: pageId, name, alias, text: content, isPublic });
  },
  deleteEventCustomPage(eventId, pageId) {
    return this.req(`events/${eventId}/page/${pageId}`, "delete")
  },
  reorderEventCustomPages(eventId, data) {
    return this.req(`events/${eventId}/pages/order`, "put", data);
  },
  getEmailTemplatesTypes() {
    return this.req(`email-templates/types`);
  },
  getEmailTemplatesSendgrid() {
    return this.req(`email-templates/sendgrid`);
  },
  getPortalEmailTemplates(portalId) {
    return this.req(`email-templates?portalId=${portalId}`);
  },
  getProductEmailTemplates(productId) {
    return this.req(`email-templates?productId=${productId}`);
  },
  createEmailTemplate(data) {
    return this.req(`email-templates`, "post", data);
  },
  updateEmailTemplate(templateId, data) {
    return this.req(`email-templates/${templateId}`, "put", data);
  },
  deleteEmailTemplate(templateId) {
    return this.req(`email-templates/${templateId}`, "delete")
  },
  getCertificateTemplates(portalId) {
    return this.req(`portals/${portalId}/cert-templates`);
  },
  downloadCertificateTemplate(portalId, name,) {
    return this.req(`portals/${portalId}/cert-templates/${name}`, "get", null, { responseType: "blob" });
  },
  uploadCertificateTemplate(portalId, ceTypeId, version, file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.req(`portals/${portalId}/cert-templates?ceTypeId=${ceTypeId}&version=${version}`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      // onUploadProgress
    });
  },
  deleteCertificateTemplate(portalId, name) {
    return this.req(`portals/${portalId}/cert-templates/${name}`, "delete")
  },
  getProducts(portalId) {
    return this.req(`portals/${portalId}/products`);
  },
  getProduct(productId) {
    return this.req(`products/${productId}`);
  },
  createProduct(portalId, data) {
    return this.req(`portals/${portalId}/products`, "post", data);
  },
  updateProduct(productId, data) {
    return this.req(`products/${productId}`, "put", data);
  },
  deleteProduct(productId) {
    return this.req(`products/${productId}`, "delete")
  },
  reorderProducts(data) {
    return this.req(`products/order`, "put", data)
  },
  getPromocodes(portalId) {
    return this.req(`portals/${portalId}/promocodes`);
  },
  createPromocode(portalId, data) {
    return this.req(`portals/${portalId}/promocodes`, "post", data);
  },
  updatePromocode(id, data) {
    return this.req(`promocodes/${id}`, "put", data);
  },
  deletePromocode(id) {
    return this.req(`promocodes/${id}`, "delete")
  },
  getPortalCustomPages(portalId) {
    return this.req(`portals/${portalId}/pages`);
  },
  createPortalCustomPage(portalId, { name, alias, content, isPublic }) {
    return this.req(`portals/${portalId}/page`, "post", { name, alias, text: content, isPublic });
  },
  updatePortalCustomPage(portalId, pageId, { name, alias, content, isPublic }) {
    return this.req(`portals/${portalId}/page`, "put", { id: pageId, name, alias, text: content, isPublic });
  },
  deletePortalCustomPage(portalId, pageId) {
    return this.req(`portals/${portalId}/page/${pageId}`, "delete")
  },
  reorderPortalCustomPages(portalId, data) {
    return this.req(`portals/${portalId}/pages/order`, "put", data);
  },
  async getFileSize(url) {
    const res = await axios({
      url,
      method: "head",
      // headers: {
      //   Authorization: `Bearer ${token}`
      // },
      // ...axiosOpts
      mode: 'no-cors',
    })
    // console.log(parseInt(res.headers["content-length"]));
    return parseInt(res.headers["content-length"]);
  },
  getRecordingsForExport(eventId) {
    return this.req(`event/${eventId}/recordings-for-export`);
  },
  updateRecordingPublishParams(id, data) {
    return this.req(`recording/${id}/exporting`, "put", data);
  },
  updateRecordingExportParams(id, data) {
    return this.req(`recording/${id}/export-params`, "put", data);
  },
  getRecordingExportSettings(id) {
    return this.req(`recording/${id}/session-data`);
  },
  getSessions(eventId) {
    return this.req(`event/${eventId}/sessions/my`);
  },
  getAllSessions(eventId) {
    return this.req(`event/${eventId}/sessions`);
  },
  getSchedule(eventId) {
    return this.req(`event/${eventId}/schedule`);
  },
  getSession(sessionId) {
    return this.req(`session/${sessionId}`);
  },
  getSessionDetails(sessionId) {
    return this.req(`session/${sessionId}/details`);
  },
  addSession(eventId, title, description, startTime, endTime, roomId, speakers, isStreaming, hasVideo, hasVideoStreaming, hasAutoSubtitles, streamLayout, hideFromSchedule, evaluationFormMetaData) {
    return this.req(`event/${eventId}/sessions`, "post", { title, description, startTime, endTime, roomId, speakers, isStreaming, hasVideo, hasVideoStreaming, hasAutoSubtitles, streamLayout, hideFromSchedule, evaluationFormMetaData });
  },
  editSession(sessionId, title, description, startTime, endTime, roomId, speakers, isStreaming, hasVideo, hasVideoStreaming, hasAutoSubtitles, streamLayout, hideFromSchedule, evaluationFormMetaData) {
    return this.req(`session/${sessionId}/`, "put", { title, description, startTime, endTime, roomId, speakers, isStreaming, hasVideo, hasVideoStreaming, hasAutoSubtitles, streamLayout, hideFromSchedule, evaluationFormMetaData });
  },
  updateSessionNotes(sessionId, data) {
    return this.req(`session/${sessionId}/notes`, "put", data);
  },
  deleteSession(sessionId) {
    return this.req(`session/${sessionId}/`, "delete");
  },
  updateSessionStatus(sessionId, value) {
    return this.req(`session/${sessionId}/status`, "put", { value });
  },
  sessionApprove(sessionId) {
    return this.req(`session/${sessionId}/approve`, "post");
  },
  sessionDecline(sessionId) {
    return this.req(`session/${sessionId}/decline`, "post");
  },
  sessionPush(sessionId) {
    return this.req(`session/${sessionId}/convert`, "post");
  },
  getSessionsReport(eventId) {
    return this.req(`event/${eventId}/sessions/csv`, 'get', null, { responseType: 'blob', });
  },
  deletePresentation(contentId) {
    return this.req(`presentation/${contentId}`, "delete");
  },
  editPresentation(contentId, name) {
    return this.req(`presentation/${contentId}`, "put", { name });
  },
  getRecording(alias) {
    return this.req("recording/" + alias);
  },
  getRecordingVideos(alias) {
    return this.req(`recording/${alias}/videos`);
  },
  getRecordingAudios(alias) {
    return this.req(`recording/${alias}/audios`);
  },
  getRecordingFiles(alias) {
    return this.req(`recording/${alias}/sources`);
  },
  deleteRecording(id) {
    return this.req("recording/" + id, "delete");
  },
  deleteRecordingVideo(id) {
    return this.req("recording/" + id + "/video", "delete");
  },
  cleanRecording(id, data) {
    return this.req("recording/" + id + "/start-cleaning", "post", data);
  },
  restoreRecordingTimeline(id) {
    return this.req("recording/" + id + "/timeline/restore", "post");
  },
  updateRecording(id, { contentId, name }) {
    return this.req("recording/" + id, "put", { contentId, name });
  },
  concatRecordings(sessionId, aliases, type) {
    const withAssets = type === "merge";
    return this.req("recording/concat", "post", { sessionId, aliases, withAssets });
  },
  cloneRecording(id) {
    return this.req(`recording/${id}/clone`, "post",);
  },
  concatPresentations(sessionId, contentIds, name) {
    return this.req("presentation/concat", "post", { sessionId, contentIds, name });
  },
  updateRecordingSubtitles(id, subtitlesBlob) {
    const formData = new FormData();
    formData.append("subtitles", subtitlesBlob, "subtitles.vtt");
    return this.req(`recording/${id}/subtitles`, "post", formData);
  },
  deleteRecordingSubtitles(id) {
    return this.req(`recording/${id}/subtitles`, "delete");
  },
  updateRecordingFiles(id, durationMs, timelineBlob, audioBlob, cutStart = null, cutDuration = null, onUploadProgress) {
    const formData = new FormData();

    if (audioBlob) {
      formData.append("audio.ogg", audioBlob, "audio.mp3");
    }

    if (timelineBlob) {
      formData.append("timeline.xml", timelineBlob, "timeline.xml");
      formData.append("duration", durationMs);
    }
    if (cutStart !== null) {
      formData.append("cutStart", cutStart);
      formData.append("cutDuration", cutDuration);
    }

    return this.req(`recording/${id}/files`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  updateRecordingFiles2(id, durationMs, timelineBlob, audioBlob, cutArgs, onUploadProgress) {
    const formData = new FormData();

    if (audioBlob) {
      formData.append("audio.ogg", audioBlob, "audio.mp3");
    }

    if (timelineBlob) {
      formData.append("timeline.xml", timelineBlob, "timeline.xml");
      formData.append("duration", durationMs);
    }
    if (cutArgs !== null) {
      formData.append("cutArgs", cutArgs);
    }

    return this.req(`v2/recording/${id}/edit`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  loadUrl(url, onProgress) {
    return axios.get(url, {
      responseType: 'arraybuffer',
      onDownloadProgress: onProgress
    });
  },

  sendFile(sessionId, file, onUploadProgress, controller) {
    const formData = new FormData();
    formData.append("presentation", file);
    return this.req(`session/${sessionId}/presentation`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress,
      signal: controller?.signal
    });
  },

  uploadSchedule(eventId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("schedule", file);
    return this.req(`event/${eventId}/sessions/import`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadFile(file, onUploadProgress) {
    const formData = new FormData();
    formData.append("file", file);
    return this.req(`file`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  getDriveFiles(eventId) {
    return this.req(`event/${eventId}/custom-videofiles`);
  },

  updateDriveFile(eventId, fileId, data) {
    return this.req(`event/${eventId}/custom-videofiles/${fileId}`, "put", data);
  },

  deleteDriveFile(eventId, fileId) {
    return this.req(`event/${eventId}/custom-videofiles/${fileId}`, "delete");
  },

  uploadDriveFile(eventId, title, sessionId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("file", file);
    if (title)
      formData.append("title", title);
    if (sessionId)
      formData.append("sessionId", sessionId);
    return this.req(`event/${eventId}/recordings-for-export`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadEventImage(eventId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("image", file);
    return this.req(`events/${eventId}/image`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadSpeakerImage(speakerId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("image", file);
    return this.req(`speaker/${speakerId}/avatar`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadExhibitorImage(exhibitorId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("image", file);
    return this.req(`exhibitor/${exhibitorId}/image`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadExhibitorVideo(exhibitorId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("video", file);
    return this.req(`exhibitor/${exhibitorId}/video`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadExhibitorFile(exhibitorId, name, locked, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("locked", locked);
    return this.req(`exhibitor/${exhibitorId}/files`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadRoomPlaceholder(roomId, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("placeholder", file);
    return this.req(`room/${roomId}/placeholder`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadRecordingVideo(id, file, onUploadProgress) {
    const formData = new FormData();
    formData.append("file", file);
    return this.req(`recording/${id}/processed-video`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  logExportRecording(alias) {
    return this.req(`recording/videoExportLog/${alias}`, "post");
  },

  logSpeakerTest(eventId, event) {
    return this.req(`event/${eventId}/webRtcTest/${event}`, "post");
  },

  startRecording(contentId, forceNew = false, timeout = 0) {
    return this.req("recording/v2/start", "post", { contentId, forceNew }, { timeout });
  },

  stopRecording(id, data = {}) {
    return this.req(`recording/${id}/stop`, "post", data);
  },

  sendRecorderStart(data) {
    return this.req("recording/recorderStart", "POST", data);
  },

  uploadRecording(audioFile, timelineFile, durationMs, contentId, startTime, videoId, onUploadProgress) {
    const formData = new FormData();

    formData.append("audio.ogg", audioFile, "audio.ogg");
    formData.append("timeline.xml", timelineFile, "timeline.xml");
    formData.append("duration", durationMs);
    formData.append("contentId", contentId);
    formData.append("startTime", startTime);
    formData.append("videoId", videoId);
    //formData.append("userId", userId);

    return this.req(`recording?duration=${durationMs}&contentId=${contentId}&startTime=${startTime}&videoId=${videoId}`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadVideoFromBackup(id, videoFile, isModerator, trackId, onUploadProgress) {
    const formData = new FormData();

    formData.append("video.webm", videoFile, "video.webm");
    if (isModerator !== null)
      formData.append("isModerator", isModerator);
    if (trackId)
      formData.append("trackId", trackId);

    return this.req(`recording/${id}/backup-video`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadRecordBackup(id, audioFile, timelineFile, liveAudio, onUploadProgress) {
    const formData = new FormData();

    formData.append("audio.ogg", audioFile, "audio.ogg");
    formData.append("timeline.xml", timelineFile, "timeline.xml");
    if (liveAudio) {
      formData.append("moderator.ogg", liveAudio, "live.ogg");
    }

    return this.req(`recording/${id}/backup`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  uploadVideoChunk(id, { videoFile, recorderId, isManual, isMp4 }, onUploadProgress = () => { }) {
    const formData = new FormData();

    formData.append("video", videoFile, "video");
    formData.append("recorderId", recorderId);
    formData.append("recordingId", id);
    if (isManual !== null && isManual !== undefined)
      formData.append("isManual", isManual);
    if (isMp4 !== null && isMp4 !== undefined)
      formData.append("isMp4", isMp4);

    return this.req(`recording/chunk/video`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress,
      timeout: 10 * 60 * 1000, 
    });
  },

  uploadRecordingVideoChunk(recordingId, { videoFile, uploadId }, onUploadProgress = () => { }) {
    const formData = new FormData();

    formData.append("video", videoFile, "video");
    // formData.append("uploadId", uploadId);
    // formData.append("recordingId", recordingId);

    return this.req(`recording/chunk/processed-video?uploadId=${uploadId}&recordingId=${recordingId}`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  finishRecordingChunkUpload({ recordingId, uploadId, data }) {
    return this.req(`recording/${recordingId}/processed-video/${uploadId}`, "POST", data);
  },

  uploadPresentationChunk(sessionId, { file, uploadId }, onUploadProgress = () => { }) {
    const formData = new FormData();

    formData.append("video", file, "video");
    // formData.append("fileName", fileName);
    // formData.append("uploadId", uploadId);
    // formData.append("recordingId", recordingId);

    return this.req(`session/${sessionId}/upload/${uploadId}/chunk`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  finishPresentationChunkUpload({ sessionId, uploadId, data }) {
    return this.req(`session/${sessionId}/upload/${uploadId}/finish`, "POST", data);
  },

  generateMixingScript({ recordingId, data }) {
    return this.req(`recording/${recordingId}/mixing-script`, "POST", data);
  },

  saveMultiCamScript({ recordingId, data }) {
    return this.req(`recording/${recordingId}/assembly-script`, "PUT", data);
  },

  createDriveRecording({ eventId, ...data }) {
    return this.req(`v2/event/${eventId}/recordings-for-export `, "POST", data);
  },

  uploadScreenChunk(id, { videoFile, recorderId }, onUploadProgress = () => { }) {
    const formData = new FormData();

    formData.append("video", videoFile, "video");
    formData.append("captureId", recorderId);
    formData.append("recordingId", id);

    return this.req(`recording/chunk/screen`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress,
      timeout: 10 * 60 * 1000, 
    });
  },

  uploadTimelineChunk(id, data) {
    return this.req(`recording/chunk/timeline`, "post", { ...data, recordingId: id });
  },

  uploadAudioChunk(id, { audioFile, timelineFile, durationMs, recorderId, isManual, isMp3 }, onUploadProgress = () => { }) {
    const formData = new FormData();

    formData.append("audio.ogg", audioFile, "audio.ogg");
    if (timelineFile)
      formData.append("timeline.xml", timelineFile, "timeline.xml");
    formData.append("recorderId", recorderId);
    formData.append("recordingId", id);
    formData.append("duration", durationMs);
    if (isManual !== null && isManual !== undefined)
      formData.append("isManual", isManual);
    if (isMp3 !== null && isMp3 !== undefined)
      formData.append("isMp4", isMp3);

    return this.req(`recording/chunk/audio`, "post", formData, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress,
      timeout: 10 * 60 * 1000, 
    });
  },

  req(path, method = 'get', data, axiosOpts = {}) {
    const url = `${apiHost}/api/${path}`;

    let token = "";
    try {
      token = localStorage.getItem(this.tokenName);
    }
    catch (ex) {
      console.warn("[SPM API]", "error reading local storage", ex);
    }

    return axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
      ...axiosOpts
    }).then(({ data }) => data);
  },

});

export default getApi;

export const makeAssetUrl = path => path.includes("https://") ? path : `${apiHost}${path}`;